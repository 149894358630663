<template>
    <div style="width: 100%;" v-loading="loading">
        <div class="top" style="margin-left: 5px;">
            <span style="margin-left:28px;">
                <el-date-picker
                    v-model="value1"
                    size="small"
                    value-format="yyyy-MM-dd"
                    @change="checkdata"
                    type="daterange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </span>
        </div>
        <div style="width: 96%; margin-left:2%; height:600px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">商户数据统计</p>
            <el-table :data="list"
                :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}">
                <el-table-column prop="time" label="日期" :width="160"></el-table-column>
                <el-table-column prop="collectionTotalMoney" label="代收总金额"></el-table-column>
                <el-table-column prop="collectionMoneyRate" label="日代收金额增长率"></el-table-column>
                <el-table-column prop="collectionTotalNum" label="代收总笔数"></el-table-column>
                <el-table-column prop="collectionNumRate" label="日代收笔数增长率"></el-table-column>
                <el-table-column v-if="user.user.roles[0].id == 1" prop="dailyServiceCharge" label="日代收服务费"></el-table-column>
                <el-table-column prop="payTotalMoney" label="代付总金额"></el-table-column>
                <el-table-column prop="payMoneyRate" label="日代付金额增长率"></el-table-column>
                <el-table-column prop="payTotalNum" label="代付总笔数"></el-table-column>
                <el-table-column prop="payNumRate" label="日代付笔数增长率"></el-table-column>
                <el-table-column v-if="user.user.roles[0].id == 1" prop="dailyServicePay" label="日代付服务费"></el-table-column>
                <el-table-column v-if="user.user.roles[0].id == 1" prop="totalService" label="总服务费"></el-table-column>
            </el-table>
            <div class="page">
                <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                    @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
            </div>
        </div>

        <div style="width: 96%; margin-left:2%; height:600px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">渠道数据统计</p>
            <el-table :data="list1"
                :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0',}">
                <el-table-column prop="time" label="日期" :width="160"></el-table-column>
                <el-table-column prop="channelName" label="渠道名称"></el-table-column>
                <el-table-column prop="collectionTotalMoney" label="渠道代收总金额"></el-table-column>
                <el-table-column prop="collectionTotalNum" label="渠道代收总笔数"></el-table-column>
                <!-- <el-table-column prop="monto" label="渠道代收笔数占比"></el-table-column> -->
                <el-table-column prop="payTotalMoney" label="渠道代付总金额"></el-table-column>
                <el-table-column prop="payTotalNum" label="渠道代付总笔数"></el-table-column>
                <!-- <el-table-column prop="balance" label="渠道代付笔数占比"></el-table-column> -->
            </el-table>
            <div class="page">
                <app-page :total="total1" :current="pageNo1" :pageSize="pageSize1"
                    @handleCurrentChange="currentchange1" @handleSizeChange="handchange1"></app-page>
            </div>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            list: [],
            list1: [],
            total: 1,
            pageNo: 1,
            pageSize: 10,

            total1: 1,
            pageNo1: 1,
            pageSize1: 10,
            selectDate: '',
            value1: null,
            pickerOptions: {
                onPick : ({maxDate, minDate}) => {
                    this.selectDate = minDate.getTime()
                    if (maxDate) {
                        this.selectDate = ''
                    }
                },
                disabledDate : (time) => {
                    if (this.selectDate !== '') {
                        const one = 24 * 24 * 3600 * 1000
                        const minTime = this.selectDate - one
                        const maxTime = this.selectDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }
                }
            }
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem("userinfo"))
        this.value1=this.getNowTime()
        this.getList()
        this.getList1()
    },
    methods:{
        getNowTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 10)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间7天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 24)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间1天前
            return [start, end]
        },
        checkdata(){
            this.getList()
            this.getList1()
        },
        getList() {
            _api.get(apiurl.mchData, {
                page: this.pageNo,
                size: this.pageSize,
                time: this.value1,
                userId:this.$route.query.userId
            }).then(res => {
                this.loading = false
                this.list = res.data.data
                this.total = res.data.totalCount
            })
        },
        getList1() {
            _api.get(apiurl.channelData, {
                page: this.pageNo1,
                size: this.pageSize1,
                time: this.value1,
                userId:this.$route.query.userId
            }).then(res => {
                this.loading = false
                this.list1 = res.data.data
                this.total1 = res.data.totalCount
            })
        },
        handchange(data) { //分页Size变化
            this.pageSize = data
            this.pageNo = 1
            this.getList()
        },
        currentchange(data) { //当前页变化
            this.pageNo = data
            this.getList()
        },

        handchange1(data) { //分页Size变化
            this.pageSize1 = data
            this.pageNo1 = 1
            this.getList1()
        },
        currentchange1(data) { //当前页变化
            this.pageNo1 = data
            this.getList1()
        },
    }

}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }
</style>